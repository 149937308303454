.review-container {
	display: flex;
	position: absolute;
	right: 0px;
	z-index: 999;
	margin-bottom: 0.5rem;
	.review-toggle {
		color: #fff;
		font-weight: bold;
		cursor: pointer;
		&:hover {
			scale: 1.05;
		}
		height: 50px;
		width: 50px;
		background: grey;
		border-radius: 5px 0px 0px 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 99;

		img {
			height: 25px;
			width: 25px;
		}
	}
	&.status-zur_freigabe {
		.review-toggle {
			background: rgb(230, 176, 0);
		}
	}
	&.status-zur_korrektur {
		.review-toggle {
			background: rgb(255, 94, 0);
		}
	}
	&.status-freigegeben {
		.review-toggle {
			background: green;
		}
	}
	.review-edit {
		box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
		h3 {
			margin-top: 0;
		}
		z-index: 999999;
		width: 400px;
		background: #eee;
		padding: 1rem;
		.formrow {
			label {
				display: block;
				font-weight: bold;
				margin-bottom: 0.5rem;
			}
			margin-bottom: 1rem;
			textarea {
				border-radius: 5px;
				height: 250px;
				width: 100%;
				padding: 0.5rem 1rem;
			}
		}
	}
}
