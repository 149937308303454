#app-footer {
  display: none;
}
.hidden-web {
  display: none !important;
}

.app-header-title-container {
  display: none;
}

.app-nav-window {
  display: none;
}
.event-time {
  display: none;
}

.app-kampagnen-thumbnail {
  height: 200px;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  margin: 20px 0;
  position: relative;
  .app-kampagnen-thumbnail-splash {
    padding: 10px;
    background-color: $toyota-red;
    color: white;
    position: absolute;
    top: 20px;
    left: 0;
    max-width: 40%;
    font-weight: bold;
    font-size: 24px;
    border-radius: 0 5px 5px 0;
  }
}

@media only screen and (max-width: 1024px) {
  .hidden-mobile {
    display: none !important;
  }
  .hidden-web {
    display: block !important;
  }
  html {
    background-color: #eee;
  }
  body {
    background-color: #eee;
    overflow-y: scroll;
  }
  .start-title {
    margin: 0;
    margin-bottom: 30px;
  }
  h1 {
    font-weight: normal !important;
  }
  h2 {
    font-weight: normal !important;
  }
  h3 {
    font-weight: normal !important;
  }
  .page-box {
    margin-bottom: 60px !important;
    margin-top: 80px !important;
  }
  .start {
    padding: 1rem;
  }
  .loader {
    height: 100vh;
    width: 100vw;
  }

  .header-bar {
    .toolbar {
      background-image: url(../components/app/app_assets/user.svg);
    }
    .search {
      right: 60px;
    }
    .help {
      display: none;
    }
    .user-id {
      display: none;
    }
    .app-header-title-container {
      display: flex;
      height: 90px;
      max-width: 80%;
      position: relative;
      color: white;
      line-height: 20px;
      justify-content: center;
      align-items: center;
      justify-self: center;
    }
  }
  .konto-sidebar {
    .toolbar-list {
      display: none;
    }
  }
  .kampagnen-preview {
    grid-template-columns: 1fr 1fr;
    font-weight: bold;
    .kampagnen-box {
      justify-content: flex-end;
    }
  }
  .app-dashboard-title {
    border-bottom: 1px solid #ccc;
  }

  .subplan-anime-container {
    height: 0;
    transition: all 0.3s ease;
    overflow: hidden;
    .open {
      height: fit-content;

      transition: all 0.3s ease;
    }
  }

  .box {
    .column-text-box {
      grid-gap: 0;
    }
    .column-text {
      padding: 0;
    }
  }

  #app-footer {
    z-index: 9999;
    font-size: 10px;
    color: #ccc;
    box-sizing: border-box;
    display: flex;
    position: fixed;
    bottom: 0;
    height: 80px;
    width: 100%;
    background-color: white;
    border-top: 1px solid #ccc;
    justify-content: space-between;
    padding: 0.8rem 1rem;
    a {
      box-sizing: border-box;
      font-weight: normal;
      display: flex;
      color: #aaa;
      text-decoration: none;
      &.active {
        color: black;
      }
    }
    .app-footer-item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .app-footer-icon {
      height: 30px;
      width: 30px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    .active {
      .app-home {
        background-image: url(../components/app/app_assets/app-home.svg);
      }

      .app-discount {
        background-image: url(../components/app/app_assets/app-discount-footer-red.svg);
      }
      .app-kam {
        background-image: url(../components/app/app_assets/app-megaphone.svg);
      }
      .app-plan {
        background-image: url(../components/app/app_assets/app-calendar.svg);
      }
    }
    .app-home {
      background-image: url(../components/app/app_assets/app-home-grey.svg);
    }

    .app-discount {
      background-image: url(../components/app/app_assets/app-discount-footer-grey.svg);
    }
    .app-kam {
      background-image: url(../components/app/app_assets/app-megaphone-grey.svg);
    }
    .app-plan {
      background-image: url(../components/app/app_assets/app-calendar-grey.svg);
    }
    .app-navi {
      background-image: url(../components/app/app_assets/app-menu.svg);
      &.active {
        background-image: url(../components/app/app_assets/app-menu-active.svg);
      }
    }
  }
  .start-title {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    &:after {
      display: none;
    }
  }
  .start-time {
    display: none;
  }
  .app-nav-window {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    z-index: 9998;
    position: fixed;
    box-shadow: 0px 2px 6px #00000029;
    border-radius: 5px;
    right: -335px;
    bottom: 70px;
    width: 300px;
    background-color: #f6f6f6;
    height: 100vh;
    overflow: hidden;
    padding: 15px;
    transition: all 0.3s ease;
    .close {
      cursor: pointer;
      &:before {
        content: url(../assets/close.svg);
        font-size: 18px;
      }
    }
    ul {
      li {
        padding: 10px 0;
        font-size: 1.2rem !important;
      }
    }
    &.open {
      right: 0;
      transition: all 0.3s ease;
    }
  }

  #marketing-plan {
    margin-top: 100px;
    .table {
      width: 95vw;
      margin: 20px auto;
    }
    .start-title {
      width: 90vw !important;
    }
    .plan-big-title {
      display: none !important;
    }
    .calendar-week-container {
      display: none;
    }
    .plan-title {
      width: 100%;

      border: none;
      margin-top: 15px;
    }
    .plan-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      border: none;

      .plan-sub-title {
        display: flex !important;
        padding: 10px;
      }
      .plan-content-sub {
        display: flex !important;
        flex-direction: column;

        .month {
          display: none !important;
        }
        .event {
          text-align: left;
          border-radius: 0;
          margin: 0;
          margin-bottom: 10px;
          font-size: 12px !important;
          p {
            width: fit-content;
            font-size: 12px !important;
          }
          .event-time {
            font-weight: normal;
          }
          img {
          }
        }
      }
      .event-time {
        display: block;
      }
    }
  }

  .product-preview-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem;
    h3 {
      margin: 10px 0;
      font-weight: bold;
    }
    .product-preview-button {
      position: relative;
      margin: 0;
      left: 0;
      margin-top: 5px;
    }
  }

  .kampagnen-padding-big {
    padding: 1.5rem;
  }
  .close-popup {
    top: 40px;
    right: 50px;
    height: 20px;
    width: 20px;
  }
  .popup-container {
    margin-top: 80px;
    max-height: 80vh;
    display: flex;
    padding: 1rem;
    flex-direction: column;
  }
  .kampagnen-row-container {
    &.layout-contained {
      padding: 0 1rem !important;
    }
    padding: 50px 0 !important;
    ul {
      li:before {
        font-size: 8px !important;
        top: 6px;
      }
    }
  }
  .slider {
    .slick-dots {
      display: none !important;
    }
    h3 {
      margin: 10px auto;
    }
    .slick-list {
      height: auto;
    }
  }
  .plan-sidebar {
    display: none;
  }
  .menge-plan {
    width: 100%;
    min-width: 0;
    overflow: scroll;
  }
  .produkttable-table {
    min-width: 1200px;
  }
  .newspage-container {
    .newspage-img {
      display: inline-block;
      width: 100%;
      height: 300px;
    }
    .newspage-text {
      margin-top: 20px;
    }
  }
  .news {
    margin: 10px auto;
    width: 100%;
    .aktuelle-content {
      padding: 15px;
      height: fit-content;
    }
    .toyota-button-small {
      position: relative;
    }
    .aktuelle-content-p {
      height: auto;
    }
  }

  .help {
    h2 {
      margin-bottom: 0;
    }
    .kampagnen-row-container {
      padding-top: 0 !important;
    }
  }
  .news-list {
    justify-items: start;
  }

  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 16px !important;
  }
}
.news-list {
  justify-items: start;
}

/* iphone X , XS, 11 Pro, 12 Mini */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3),
  only screen and (min-device-width: 390px) and (max-device-height: 844px) and (-webkit-device-pixel-ratio: 3),
  only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 2),
  only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 3),
  only screen and (min-device-width: 428px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .header-bar {
    height: 90px;

    .logo-wrap {
      top: 10px;
    }
    .toolbar {
      height: 90px;
      top: 10px;
    }
    .search {
      right: 60px;
      top: 15px;
    }
  }
  .konto-sidebar {
    top: 90px;
  }
  #app-footer {
    padding-bottom: 1.2rem;
    padding-top: 0.8rem;
  }
  .header-bar .app-header-title-container {
    display: flex;
    height: 90px;
    max-width: 80%;
    margin-top: 10px;
    position: relative;
    color: white;
    line-height: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    justify-self: center;
  }
}

@import "./appstyle/tablet.scss";
