@font-face {
    font-family: "ToyotaType";
    font-weight: normal;
    src: url("../assets/fonts/ToyotaType-Regular.otf");
}

@font-face {
    font-family: "ToyotaType";
    font-weight: bold;
    src: url("../assets/fonts/ToyotaType-Semibold.otf");
}

$break-large: 1250px;
$break-small: 700px;

@import "./clean.scss";
@import "./variable.scss";
@import "./ie.scss";

@mixin font-normal {
    font-size: 16px;
}

@mixin font-big {
    font-weight: bold;
    font-size: 48px;
    font-family: "ToyotaType", sans-serif;
}

@mixin font-small {
    font-size: 16px;
}
html {
    background: #f6f6f6;
    scroll-behavior: smooth;
}

html,
body {
    max-width: 100%;
}
body * {
    -webkit-tap-highlight-color: transparent !important;
}

body {
    font-family: "ToyotaType", sans-serif;
    color: $toyota-black;
    font-size: 16px;
}

h1 {
    font-weight: bold;
    font-size: 40px;
    font-family: "ToyotaType", sans-serif;
    @media screen and (max-width: $break-large) {
        font-size: 32px;
    }
    @media screen and (max-width: $break-small) {
        font-size: 24px;
    }
}

h2 {
    font-weight: bold;
    font-size: 28px;
    font-family: "ToyotaType", sans-serif;
    margin-bottom: 30px;
    @media screen and (max-width: $break-large) {
        font-size: 24px;
    }
    @media screen and (max-width: $break-small) {
        font-size: 18px;
    }
}

h3 {
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: 10px;
    font-family: "ToyotaType", sans-serif;
    margin-bottom: 30px;
    @media screen and (max-width: $break-large) {
        font-size: 1.3rem;
    }
    @media screen and (max-width: $break-small) {
        font-size: 1.2rem;
    }
}

h5 {
    font-weight: bold;
    font-size: 1rem;
    font-family: "ToyotaType", sans-serif;
    text-transform: uppercase;
}

p {
    line-height: 1.6;
    color: $toyota-grey;
    @include font-normal();
    margin-bottom: 30px;
    word-wrap: normal;
    img {
        margin: 0 auto;
        display: block;
    }
}
ul li {
    color: $toyota-grey;

    @include font-small();
}

a {
    font-weight: bold;
}

@mixin Button-big {
    display: inline-block;
    line-height: 58px;
    height: 58px;
    background: $toyota-red;
    color: white;
    text-align: center;
    width: fit-content;
    padding: 0 24px;
    cursor: pointer;
    font-weight: bold;

    font-family: "ToyotaType", sans-serif;
    &:hover {
        transition: 0.3s ease-in;
        background: $toyota-dark-red;
    }
    @include font-normal;
}

@mixin Button-small {
    display: inline-block;

    background: $toyota-red;
    color: white;
    text-align: center;
    width: fit-content;
    padding: 0.5rem 1rem;
    border-radius: 3px;
    font-weight: bold;
    box-shadow: 0px 2px 6px #00000029;
    cursor: pointer;
    font-family: "ToyotaType", sans-serif;
    margin-bottom: 5px;
    &:hover {
        transition: 0.3s ease-in;
        background: $toyota-dark-red;
        box-shadow: none;
        transform: translateY(2px);
    }
    @include font-normal;

    @media screen and (max-width: $break-small) {
        margin-left: 0;
        margin-top: 0;
        height: auto;
    }
}

.toyota-button {
    @include Button-big();
}

.toyota-button-small {
    @include Button-small();
    &.grey {
        background-color: $toyota-light-grey;
    }
}

.line {
    width: 100%;
    height: 1px;
    background: linear-gradient(black 0, black 100%);
    margin: 5px 0;
}

.icon-print {
    vertical-align: -3px;
    padding-right: 5px;
    cursor: pointer;
    &:after {
        content: url(../assets/print.svg);
    }
}

.icon-next {
    vertical-align: -2px;
    padding-right: 5px;
    padding-left: 10px;
    &:after {
        content: url(../assets/icon-next.svg);
    }
}

.page-box {
    position: relative;
    display: block;
    margin: 150px auto;
    max-width: 1250px;

    @media screen and (max-width: 1200px) {
        width: auto;
        margin: 120px auto;
    }
}

.start {
    @media screen and (max-width: 1200px) {
        padding: 0 20px;
    }
}

.start-title {
    display: block;
    margin-bottom: 30px;
    h1 {
        float: left;
    }
    h2 {
        font-size: 32px;
    }
    span {
        font-size: 16px;
        line-height: 30px;
        &.time {
            color: $toyota-light-grey;
        }
        &.print {
            color: #595d60;
            font-weight: 600;
            cursor: pointer;
        }
    }
    .start-title-main {
        float: left;
        span {
            color: $toyota-light-grey;
        }
    }
    .start-time {
        float: right;
        text-align: right;
        @media screen and (max-width: $break-small) {
            text-align: left;
            float: none;
        }
    }
    @media screen and (max-width: 700px) {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 30px;
    }
}

table {
    width: 100%;
    border-collapse: collapse;
    color: #4d4f53;
    border-radius: 3px;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
    margin-bottom: 5px;
    border-radius: 7px;

    thead {
        td {
            background: #e6e6e6;
            font-weight: bold;
            padding-left: 0.5rem;
            padding-bottom: 1rem;
            padding-top: 1rem;
            font-weight: bold;
        }
        tr {
            background: #e6e6e6;
            font-weight: bold;
            padding-left: 0.5rem;
            padding-bottom: 1rem;
            padding-top: 1rem;
            font-weight: bold;
        }
    }
    tbody {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
    }
    td {
        border: 1px solid #eee;
        padding: 0.5rem;
        padding-bottom: 1rem;
        padding-top: 1rem;
        img {
            display: block;
            margin: 0 auto;
        }
        ul {
            padding-left: 1rem;
            list-style: none;
            margin: 0;
            li {
                margin-bottom: 0.2rem;
            }
            li:before {
                content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
                color: red; /* Change the color */
                font-weight: bold; /* If you want it to be bold */
                display: inline-block; /* Needed to add space between the bullet and the text */
                width: 1em; /* Also needed for space (tweak if needed) */
                margin-left: -1em; /* Also needed for space (tweak if needed) */
            }
            @media screen and (max-width: 800px) {
                li:before {
                    content: "•"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
                }
            }
        }
        &.wap {
            color: red;
        }
    }
}

@import "../components/index.scss";
@import "./app.scss";
