@page {
    size: "A4";
    margin-top: 20mm;
}

@media print {
    * {
        color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }
    html,
    body {
        transform: scale(0.8);
        transform-origin: top left;
        overflow: visible;
    }
    .print-hide {
        visibility: hidden !important;
        display: none !important;
    }
    .header-bar {
        display: none !important;
    }
    .page-box {
        margin-top: 0;
        max-width: 1400px !important;
        min-width: 1400px;
    }
    .plan-sidebar {
        display: none !important;
    }
    .kampagnen-full-container.opened {
        margin: 0;
    }
    .kampagnen {
        display: block !important;
    }
    .kampagnen-full-container {
        overflow: visible;
    }
    .Quicklinks {
        display: none;
    }

    .review-container {
        display: none;
    }
    .row-animation {
        opacity: 1 !important;
        transform: none !important;
    }
    .konto-searchbar {
        display: none;
    }
    .konto-sidebar {
        display: none;
    }
    .plan {
        display: block;
        position: relative;
        margin-top: -150px;
        max-width: auto;
        width: 100%;
        transform: scale(0.85);
    }
}
