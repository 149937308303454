@import "../../../../../../style/variable.scss";
.Quicklinks {
    position: fixed;
    right: 0;
    top: 45%;
    transform: translateY(-50%);
    z-index: 999;
    a {
        background: $toyota-red;

        height: 60px;
        width: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #fff;
        font-size: 10px;
        align-items: center;
        border-radius: 5px 0px 0px 5px;
        transition: all 0.3s ease;
        margin-bottom: 0.5rem;

        img {
            height: 30px;
            width: 30px;
        }
        &:hover {
            transition: all 0.3s ease;
            background: $toyota-grey;
            img {
                transform: scale(1.1);
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .Quicklinks {
        display: none;
    }
}
