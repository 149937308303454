.icon-unsaved {
    display: inline-block;
    height: 16px;
    width: 16px;
    vertical-align: -2px;
    margin-right: 10px;
    background-image: url(../../../assets/save.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.icon-saving {
    display: inline-block;
    height: 22px;
    width: 22px;

    vertical-align: -5px;
    margin-right: 5px;
    background-image: url(../../../assets/icons-saving.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    animation: rotate 1.5s linear infinite;
}

.checked {
    height: 25px;
    width: 25px;
    vertical-align: -5px;
    margin-right: 5px;
}
