@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .welcome {
        display: flex !important;
        > div {
            display: block;
            width: 50%;
        }
    }
    .dashboard-grid {
        display: flex !important;
        > div {
            display: block;
            width: 25%;
        }
    }
    .table .month-title {
        display: flex;
    }
    .kampagnen-preview {
        display: flex !important;
        flex-wrap: wrap;
        .kampagnen-box {
            margin-right: 15px;
        }
        > a {
            display: block !important;
            width: 20% !important;
            .title {
                z-index: 799;
                position: relative;
            }
        }
    }
    .produkttable-title {
        display: -ms-grid !important;
        -ms-grid-columns: 4fr 2fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr !important;

        > div:nth-child(1) {
            -ms-grid-column: 1;
        }
        > div:nth-child(2) {
            -ms-grid-column: 2;
        }
        > div:nth-child(3) {
            -ms-grid-column: 3;
        }
        > div:nth-child(4) {
            -ms-grid-column: 4;
        }
        > div:nth-child(5) {
            -ms-grid-column: 5;
        }
        > div:nth-child(6) {
            -ms-grid-column: 6;
        }
        > div:nth-child(7) {
            -ms-grid-column: 7;
        }
        > div:nth-child(8) {
            -ms-grid-column: 8;
        }
        > div:nth-child(9) {
            -ms-grid-column: 9;
        }
    }
    .produkttable-content {
        display: -ms-grid;
        -ms-grid-columns: 4fr 2fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr !important;

        > div:nth-child(1) {
            -ms-grid-column: 1;
        }
        > div:nth-child(2) {
            -ms-grid-column: 2;
        }
        > div:nth-child(3) {
            -ms-grid-column: 3;
        }
        > div:nth-child(4) {
            -ms-grid-column: 4;
        }
        > div:nth-child(5) {
            -ms-grid-column: 5;
        }
        > div:nth-child(6) {
            -ms-grid-column: 6;
        }
        > div:nth-child(7) {
            -ms-grid-column: 7;
        }
        > div:nth-child(8) {
            -ms-grid-column: 8;
        }
        > div:nth-child(9) {
            -ms-grid-column: 9;
        }
    }

    .checklist-content,
    .checklist-title {
        -ms-grid-columns: 1fr 2.5fr 3fr 2fr 2fr 1fr 1fr;
        display: -ms-grid;
        > div:nth-child(1) {
            -ms-grid-column: 1;
        }
        > div:nth-child(2) {
            -ms-grid-column: 2;
        }
        > div:nth-child(3) {
            -ms-grid-column: 3;
        }
        > div:nth-child(4) {
            -ms-grid-column: 4;
        }
        > div:nth-child(5) {
            -ms-grid-column: 5;
        }
        > div:nth-child(6) {
            -ms-grid-column: 6;
        }
        > div:nth-child(7) {
            -ms-grid-column: 7;
        }
        > div:nth-child(8) {
            -ms-grid-column: 8;
        }
        > div:nth-child(9) {
            -ms-grid-column: 9;
        }
    }
    .kampagnen-row {
        display: flex !important;
        flex-wrap: wrap;
        .span_4 {
            width: 33%;
        }
        .span_3 {
            width: 25%;
        }
        .span_6 {
            width: 50%;
        }
        .span_9 {
            width: 75%;
        }
        .column-text-box {
            display: flex;
            > div {
                width: 33%;
            }
        }
    }
    .month-title {
        -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        display: flex;
        > div {
            flex-grow: 1;
        }
        > div:nth-child(1) {
            -ms-grid-column: 1;
        }
        > div:nth-child(2) {
            -ms-grid-column: 2;
        }
        > div:nth-child(3) {
            -ms-grid-column: 3;
        }
        > div:nth-child(4) {
            -ms-grid-column: 4;
        }
        > div:nth-child(5) {
            -ms-grid-column: 5;
        }
        > div:nth-child(6) {
            -ms-grid-column: 6;
        }
        > div:nth-child(7) {
            -ms-grid-column: 7;
        }
        > div:nth-child(8) {
            -ms-grid-column: 8;
        }
        > div:nth-child(9) {
            -ms-grid-column: 9;
        }
        > div:nth-child(10) {
            -ms-grid-column: 10;
        }
        > div:nth-child(11) {
            -ms-grid-column: 11;
        }
        > div:nth-child(12) {
            -ms-grid-column: 12;
        }
    }
    .table .plan-content {
        -ms-grid-columns: 2fr 10fr;
        display: -ms-grid;
        > div:nth-child(1) {
            -ms-grid-column: 1;
        }
        > div:nth-child(2) {
            -ms-grid-column: 2;
        }
    }
    .week-grid {
        display: flex;
        flex-grow: 1;
    }
    .plan-big-title {
        display: none;
    }
    .calendar-week-container {
        display: none;
    }
    .user-id {
        display: flex;
        flex-direction: column;
        .handler {
            margin-bottom: 15px;
        }
    }
}
