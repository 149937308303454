.news-list {
	width: 100%;
	position: relative;
}

.news {
	position: absolute;
	float: left;
	z-index: 1;
	width: 400px;
	height: fit-content;
	box-shadow: 0px 2px 2px #00000029;
	opacity: 0;
	transform: translateY(10px);
	ul,
	ol {
		padding-left: 1rem;
		list-style: none;
		margin: 0;
		li {
			margin-bottom: 0.2rem;
		}
		li:before {
			content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
			color: red; /* Change the color */
			font-weight: bold; /* If you want it to be bold */
			display: inline-block; /* Needed to add space between the bullet and the text */
			width: 1em; /* Also needed for space (tweak if needed) */
			margin-left: -1em; /* Also needed for space (tweak if needed) */
		}
		@media screen and (max-width: 800px) {
			li:before {
				content: '•'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
			}
		}
	}
	&.active {
		opacity: 1;
		transform: translateY(0px);
		transition: all 0.2s ease;
	}
	.icon-calendar {
		vertical-align: -0.5px;
		padding-right: 7px;
		&:after {
			content: url(../../../assets/icon-calendar.svg);
		}
	}

	.news-rows {
		.kampagnen-row-container {
			&.layout-contained {
				padding: 0 !important;
			}
		}
	}

	@media screen and (max-width: $break-small) {
		margin: 10px auto;
		width: 90%;
	}
	span {
		color: #bcbcbc;
		line-height: 30px;
	}

	.aktuelle-bild {
		height: 205px;
		width: 100%;
		background-position: center;
		background-size: cover;
		border-radius: 3px 3px 0px 0px;
	}
	.aktuelle-content {
		background-color: white;
		padding: 25px;
		height: fit-content;
		border-radius: 3px 3px 0px 0px;
		box-shadow: 0px 2px 2px #00000029;
	}
	.aktuelle-content-p {
		height: fit-content;
		overflow: hidden;
		margin-bottom: 25px;
	}
	.toyota-button-small {
	}
}

.newspage-container {
	position: relative;
	margin: 10vh auto;
	width: 80%;
	max-width: 1000px;
	box-shadow: 0px 2px 6px #00000029;
	max-height: 80vh;
	padding: 50px;
	background-color: #4d4f53;
	color: white;
	box-sizing: border-box;
	overflow-y: scroll;
	.newspage-img {
		position: relative;
		width: 100%;
		height: 300px;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		&:before {
			content: '';
			background-color: rgba(0, 0, 0, 0.3);
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
	.newspage-text {
		width: 100%;
		box-sizing: border-box;
		padding: 30px 0;
		font-size: 1rem;
		line-height: 1.8;

		p {
			color: white !important;
			margin: 0;
		}
	}
	a {
		color: $toyota-red;
		text-decoration: underline;
	}
}
.news-close {
	margin-top: 20px;

	margin-bottom: 30px;
}
.news-back {
	margin-top: 2rem;
}

.news-modal {
	z-index: 9999;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.3);
	.news-title-container {
		width: 80%;
		color: white;
		position: absolute;
		bottom: 20px;
		left: 30px;
	}
}

.newspage-text {
	ul,
	ol {
		padding-left: 1rem;
		list-style: none;
		margin: 0;
		li {
			margin-bottom: 0.2rem;
		}
		li:before {
			content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
			color: red; /* Change the color */
			font-weight: bold; /* If you want it to be bold */
			display: inline-block; /* Needed to add space between the bullet and the text */
			width: 1em; /* Also needed for space (tweak if needed) */
			margin-left: -1em; /* Also needed for space (tweak if needed) */
		}
		@media screen and (max-width: 800px) {
			li:before {
				content: '•'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
			}
		}
	}
}

@media only screen and (max-width: 800px) {
	.newspage-container {
		width: 95%;
		margin: 100px auto;
		max-height: 600px;
		.newspage-text {
			overflow: scroll;
			max-height: 200px;
			padding: 10px 30px 10px 30px;
		}
	}
}
