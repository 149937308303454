.column-text-box {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;

    @media screen and (max-width: 1400px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 700px) {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }
}
.column-text {
    box-sizing: border-box;
    display: block;
}

@media only screen and (max-width: 800px) {
    .column-text {
        box-sizing: border-box;
        padding: 0px 10px;
       
    }
}
