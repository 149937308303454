.trenner {
    z-index: 1000;
    margin-top: 10px;
    margin-bottom: 12px;
    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
    }
    &.white {
        background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0.6),
            rgba(255, 255, 255, 0.6)
        );
    }
    &.grey {
        background: linear-gradient(to right, #4d4f53, #4d4f53);
    }
}
