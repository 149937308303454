.kampagnen-preview {
    max-width: 1250px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1rem;
    @media screen and (min-width: $break-small) and (max-width: $break-large) {
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: $break-small) {
        grid-template-columns: 1fr;
    }
    .kampagnen-box {
        position: relative;
        height: 15vh;
        background-size: cover !important;
        background-position: center !important;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-align: left;
        text-decoration: none;
        transition: transform 0.2s ease;

        &:hover {
            transform: scale(1.1);
            transition: transform 0.2s ease;
        }
        .kampagnen-box-content {
            z-index: 2;
            .title {
                font-size: 1.2rem;
                color: white;
                margin: 0;
            }

            span {
                color: white;
            }
        }
        &:before {
            display: inline-block;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            z-index: 1;
            content: "";
            border-radius: 5px;
            background: rgba(0, 0, 0, 0.3);
        }
    }
}

@import "./app-kampagnenPreview.scss";
