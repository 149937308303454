$break-large: 1250px;
@import "../../../style/variable.scss";
@import "./start-app.scss";
@page {
    margin: 100px auto;
}

@media print {
    .toyota-button-small {
        display: none;
    }
    .planbox {
        page-break-inside: avoid;
    }
}

.start-title:after {
    clear: both;
    content: "";
    display: block;
    font-size: 0;
    height: 0;
    visibility: hidden;
}

.start h3 {
    font-size: 1.5rem;

    margin-top: 2rem;
    margin-bottom: 1rem;
}

.app-preview {
    display: block;

    background-color: $toyota-red;
    color: #fff;
    padding: 0.5rem 1rem;
    width: 200px;
    font-weight: bold;
    text-align: left;
    border-radius: 3px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    background-image: url("../../../assets/icons8-smartphone-tablet.svg");
    background-repeat: no-repeat;
    background-size: 2rem;
    background-position: top 50% right 1rem;
    &:hover {
        background-color: $toyota-black;
        transition: all 0.3s ease;
    }
}

.welcome {
    display: grid;
    grid-template-columns: 1.5fr 2fr;
    @media screen and (max-width: $break-small) {
        grid-template-columns: 1fr;
    }
    .welcome-image {
        min-height: 300px;
        background-size: cover;
        background-position: center;
        position: relative;
        .dealerData {
            position: absolute;
            background: $toyota-black;
            color: #fff;
            font-weight: bold;
            padding: 0.5rem 1rem;
            bottom: 50px;
            right: 0px;
            border-radius: 5px 0 0 5px;
        }
    }
    .welcome-text {
        .toyota-button-small {
            margin-top: 2rem;
            margin-right: 1rem;
            img {
                display: inline-block;
                height: 24px;
                width: 24px;
                margin-bottom: -5px;
                margin-left: -5px;
            }
        }
        .toyota-button-small.secondary {
            background-color: $toyota-light-grey;
            text-decoration: none;
            @media screen and (max-width: 1150px) {
                margin-left: 0;
            }
        }
    }
}

.dashboard-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1rem;
    @media screen and (min-width: $break-small) and (max-width: $break-large) {
        grid-template-columns: 1fr 1fr !important;
    }
    @media screen and (max-width: $break-small) {
        grid-template-columns: 1fr !important;
    }
    .kampagnen-box-container {
        display: flex;
        flex-direction: column;
        .aktion-content {
            h3 {
                margin-top: 0;
            }
            position: relative;
            background: white;
            padding: 1rem;
            border-radius: 5px;
            transform: translateY(-30px);
            box-shadow: 0px 1px 2px #00000029;
        }

        .kampagnen-box-dashboard {
            overflow: hidden;
            position: relative;
            background-size: cover;
            background-position: center;

            display: flex;

            justify-content: center;

            height: 280px;
            align-items: center;
            text-decoration: none;
            margin-bottom: 0;
            border-radius: 5px 5px 0 0px;

            .kampagnen-box-icon {
                position: relative;
                height: 50%;
                width: 50%;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 40%;
                border-radius: 15px;
            }
            .title,
            .subtitle,
            span {
                color: #fff;
                position: relative;
                z-index: 1;
            }
            .title {
                font-size: 1.5rem;
                font-weight: bold;
            }
            text-align: left;
        }
    }
}

.welcome-video {
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    video {
        max-width: 100%;
        width: 1200px;
        height: auto;
    }
    .close {
        cursor: pointer;
        position: absolute;
        top: 2rem;
        right: 2rem;
        height: 50px;
        width: 50px;
    }
}
#plan-overview {
    margin-top: 0;
}

.plan-overview-title {
    display: grid;
    height: 30px;
    border-radius: 3px;
    border-bottom: solid 1px darkgrey;
    border-top: solid 1px darkgrey;
    background-color: #aaafb2;
    color: white;
    font-weight: bold;
    .month-list {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.plan-overview {
    padding-top: 10px;
    padding-bottom: 5px;
    background-color: white;
    box-shadow: 0px 1px 2px #00000029;
}
.plan-overview-content {
    position: relative;
    width: 100%;

    .plan-overview-container {
        display: grid;
        width: 100%;
        background-color: white;
        padding: 2px;
        box-sizing: border-box;
    }
    .plan-overview-event {
        box-sizing: border-box;
        height: 6px;
        position: relative;
        display: flex;
        border-radius: 5px;
        cursor: pointer;
        a {
            position: relative;
            height: 6px;
            flex-grow: 1;
        }
        &:hover {
            transform: scale(1.05);
            transition: 0.2s ease;
        }
    }
}

.news-slider-container {
    border-radius: 5px;
    display: flex;
    position: relative;
    width: 100%;
    height: 400px;
    margin-bottom: 20px;
    border-radius: 5px;
    @media screen and (max-width: 700px) {
        flex-direction: column;
        height: fit-content;
    }
    .slick-slider {
        position: relative;
        height: 100%;
    }
    .news-image-single-wrapper {
        width: 100%;
        height: 400px;
    }
    .news-slider-image-container {
        box-shadow: 0px 1px 2px #00000029;
        position: relative;
        width: 65%;
        height: 100%;
        @media screen and (max-width: 700px) {
            width: 100%;
        }
        .slick-prev:before,
        .slick-next:before {
            color: white !important;
        }
        .news-image {
            position: relative;
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: cover;
            span {
                font-size: 15px;
            }
            &:before {
                content: "";
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.2);
            }
            .new-image-title {
                position: absolute;
                font-size: 32px;
                font-weight: bold;
                color: white;
                left: 40px;
                bottom: 30px;
                max-width: 700px;
            }
        }
    }
    .news-slider-list-container {
        border-radius: 5px;
        box-shadow: 0px 1px 2px #00000029;
        position: relative;
        width: 35%;
        height: 100%;
        background: $toyota-grey;
        cursor: pointer;
        color: white;

        @media screen and (max-width: 700px) {
            display: none;
        }
        .slick-vertical {
            height: 300px;
        }
        .slick-list {
            height: 300px;
        }
        .slick-current {
            background-color: $toyota-light-grey;

            transition: 0.3s all;
        }
        .news-title-box {
            span {
                font-size: 12px;
            }
            box-sizing: border-box;
            font-size: 20px;
            height: 150px;
            display: flex;
            padding: 10px 20px;
            align-items: center;
        }
        .news-title-button {
            position: absolute;
            right: 20px;
            bottom: 20px;
        }
    }
}
.news-title-sigle-container {
    > div {
        color: white;
        text-decoration: none;
    }
}
