.timeplan-month {
    width: 100%;
    display: grid;
    text-align: center;
    min-width: 800px;
    div {
        border-right: 1px solid grey;
        border-left: 1px solid grey;
        max-height: 20px;
    }
}
.timeplan {
    margin-bottom: 80px;
    max-width: 99%;
}
.timeplan-content {
    width: 99.8%;
    height: auto;
    display: grid;
    border: 1px solid grey;
    min-width: 800px;
}

.timeplan-event {
    box-sizing: border-box;
    z-index: 500;
    padding: 3px 6px;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
    text-align: center;
    margin-top: 3px;
    margin-bottom: 3px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0.85;

    &:hover {
        transform: scale(1.02);
        transition: 0.2s all ease;
    }
    a {
        display: flex;
        justify-content: center;
        text-decoration: none !important;
        &:hover {
            text-decoration: none;
        }
    }
    > div {
        max-width: 100%;
    }
    .download-timeplan {
        background-image: url(../../../../../../assets/downlod_excel.svg);
        height: 20px;
        width: 20px;
        margin-left: 5px;
    }
}
.timeplan-termin {
    display: grid;
    font-weight: bold;
    word-break: keep-all;
    grid-template-rows: 1fr;
    min-width: 800px;

    .timeplan-termin-detail {
        grid-row-start: 1;
        .line {
            height: 14px;
            width: 2px;
            margin: 0 auto;
            background: #333;
            position: relative;
            z-index: 1000;

            &:before {
                position: absolute;
                top: -3px;
                transform: translateX(-2px);
                height: 6px;
                width: 6px;
                display: block;
                border-radius: 50%;
                content: "";
                background: #333;
            }
        }
        .bezeichnung {
            position: absolute;
            width: 150px;
        }
    }
}

.month-name {
    display: block;
    margin-top: -25px;
    line-height: 60px;
}
