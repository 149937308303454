.zitat-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 20px;
    align-items: center;
    grid-gap: 20px;
    min-height: 200px;
    border-radius: 5px;
    max-width: 700px;
    margin: 0 auto;
    .zitat-bild {
        justify-self: center;
        height: 200px;
        width: 200px;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .zitat-person {
        text-align: right;
    }
    .zitat-text {
        line-height: 1.1;
        font-size: 2.5rem;
    }
}
