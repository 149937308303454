.img-modal {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    img {
        max-width: 80%;
        max-height: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    i {
        position: absolute;
        top: 20%;
        right: 10%;
        cursor: pointer;
        &:before {
            content: url(../../../../../../assets/close_white.svg);
        }
    }
}
.kampagnen-image-slider {
    .slick-prev,
    .slick-next {
        top: 200px;
    }
}

.slick-dots {
    li:before {
        content: "" !important;
    }
    @media screen and (max-width: 700px) {
        bottom: -45px;
    }
}

.slick-slider {
    max-width: 100%;
}

.slick-next::before,
.slick-prev::before {
    color: #282830;
}
.slick-prev {
    left: 2%;
    z-index: 999;
}

.slick-next {
    right: 2%;
}
