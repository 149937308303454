.kampagnen-image {
    overflow: hidden;
    position: relative;
    > img {
        width: 100%;
        height: auto;
    }
    &.cover img {
        object-fit: cover;
        object-position: 50% 50%;
    }
    .splash {
        display: inline-block;
        padding: 8px 12px;
        position: absolute;
        top: 10%;
        right: 0;
        max-width: 40%;
        border-radius: 3px 0 0 3px;
        color: white;
        font-size: 20px;
        font-weight: bold;
        box-shadow: 0px 1px 2px #00000029;
    }
}
