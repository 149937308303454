.kampagnen-title-wrapper {
    position: relative;

    width: 100%;
    overflow: hidden;
    .slick-prev:before,
    .slick-next:before {
        color: white !important;
    }
    .slick-slider {
        height: 400px;
    }
    .wow {
        position: absolute;
        top: 0px;
        right: 0px;
        height: 60px;
        z-index: 99;
        @media screen and (max-width: 800px) {
            top: 320px;
        }
    }
}

.slick-slide {
    position: relative;
}

.kampagnen-title {
    z-index: 1;
    height: 400px;
    width: 100%;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: flex-start;

    &.horizon_center {
        justify-content: center;
        text-align: center;
        .kampagnen-title-box {
            &.vertical_center {
                padding: 30px;
            }
        }
    }
    &.left {
        justify-content: flex-start;
    }
    &.right {
        justify-content: flex-end;
        .kampagnen-title-box {
            padding: 0 70px 0 30px;
        }
    }
    &:before {
        z-index: 2;
        position: absolute;
        width: 100%;
        height: 100%;
        content: "";
        background: rgba(0, 0, 0, 0.3);
    }
    @media screen and (max-width: 800px) {
        display: flex;
    }
}

.kampagnen-title-box {
    z-index: 3;
    position: relative;
    display: flex;
    padding: 0 30px 0 70px;
    max-width: 35%;
    p {
        display: block;
        position: relative;
    }
    @media screen and (max-width: 1500px) {
        padding: 0 50px 0 20px;
        .kampagnen-title-headline {
            transform: scale(0.7) !important;
        }
    }
    @media screen and (max-width: 800px) {
        height: fit-content;
        padding: 0;
        max-width: 100%;
        flex-grow: 1;
    }
    &.top {
        align-items: flex-start;
        @media screen and (max-width: 800px) {
            justify-content: center;
        }
    }
    &.vertical_center {
        align-items: center;
        @media screen and (max-width: 800px) {
            justify-content: center;
        }
    }
    &.bottom {
        align-items: flex-end;
        @media screen and (max-width: 800px) {
            justify-content: center;
        }
    }
}

.kampagnen-title-headline {
    h2 {
        color: white;
        font-size: 34px;
        @media screen and (max-width: 800px) {
            font-size: 24px;
        }
    }
    h3 {
        color: white;
        @media screen and (max-width: 800px) {
            font-size: 18px;
        }
    }
    p {
        line-height: 1.2;
        margin: 0;
        @media screen and (max-width: 800px) {
            font-size: 12px;
        }
    }
}

.kampagnen-title-headline2 {
    position: absolute;
    z-index: 3;
    h2 {
        color: white;
        font-size: 34px;
        @media screen and (max-width: 800px) {
            font-size: 24px;
        }
    }

    p {
        line-height: 1.2;
        margin: 0;
    }

    h3 {
        color: white;
    }

    &.bottom {
        bottom: 7%;
    }
    &.vertical_center {
        top: 50%;
        transform: translateY(-50%);
    }
    &.left {
        left: 5%;
    }
    &.right {
        right: 5%;
    }
    &.top {
        top: 7%;
    }
}
.extend-container {
    z-index: 2;
    position: absolute;
    width: 40%;
    top: 0;
    right: 0;
    display: flex;
    text-align: center;
    height: 80px;
    font-size: 32px;
    color: white;
    > div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .extend-wow {
        img {
            max-height: 50px;
            width: auto;
        }
        flex-grow: 1;
    }
    .extend-tip {
        img {
            max-height: 50px;
            width: auto;
        }
        flex-grow: 1;
    }
}
