@media only screen and (max-width: 1440px) {
    .kampagnen-preview {
        .kampagnen-box {
            opacity: 0;
            transform: translateY(10px);
            &.show {
                opacity: 1;
                transform: translateY(0);
                transition: all 0.2s ease-in;
            }
        }
    }
    .kampagnen-preview {
        grid-gap: 1rem;
        &.big {
            grid-template-columns: 1fr;
            .kampagnen-box {
                .kampagnen-box-content {
                    .title {
                        width: 80%;
                        font-size: 22px;
                    }
                }
            }
        }
        &.big {
            .kampagnen-box-red-line {
                position: absolute;
                top: 0;
                right: 0;
                height: 5px;
                width: 48%;
                background-color: #ff0022;
                z-index: 5;
            }
        }

        &.small {
            grid-template-columns: 1fr 1fr;
            font-weight: bold;
            .kampagnen-box {
                .kampagnen-box-content {
                    .title {
                        width: 80%;
                    }
                }
            }
        }
    }
    .kampagnen-preview-filter {
        width: 100%;
        padding: 5px 10px;
        border: transparent;
    }
}

@media only screen and (max-width: 600px) {
    .kampagnen-preview {
        &.big {
            .kampagnen-box {
                .kampagnen-box-content {
                    .title {
                        width: 80%;
                        font-size: 0.8rem;
                    }
                }
            }
        }
        &.middle {
            grid-template-columns: 1fr;
            .kampagnen-box {
                padding: 0 !important;
                height: 100px;
                width: 100%;
                background-size: 50% 100% !important;
                background-position: left !important;
                background-repeat: no-repeat !important;
                padding-top: 5rem;
                .kampagnen-box-content {
                    position: absolute;
                    height: 100%;
                    width: 50%;
                    background-color: white;
                    left: 50%;
                    display: flex;
                    align-items: center;
                    border-radius: 0 5px 5px 0;
                    .title {
                        span {
                            color: black;
                        }
                        position: relative;
                        bottom: 0;
                        padding-left: 10px;
                        max-width: 70%;
                        font-size: 0.8rem;
                    }
                    .kampagnen-box-content-arrow {
                        position: absolute;
                        content: "";
                        height: 20px;
                        display: inline-block;
                        width: 20px;
                        background-size: contain;
                        background-image: url(../../../assets/arrow.svg);
                        right: 10px;
                    }
                }
                &:before {
                    content: "";
                    width: 50%;
                }
            }
        }
        &.small {
            .kampagnen-box {
                .kampagnen-box-content {
                    .title {
                        font-size: 0.8rem;
                        margin: 0;
                        position: absolute;
                        bottom: 1rem;
                    }
                }
            }
        }
    }
}
