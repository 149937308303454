.login-wrapper {
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../../assets/mainimage.jpg");
    background-size: cover;
    .login-box {
        padding: 3rem;
        background: white;
        box-shadow: 0px 2px 2px #00000029;
        text-align: center;
        .login-title {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            i {
                display: inline-block;
                content: url(../../../assets/login-logo.svg);
                align-self: -10px;
                margin: 0 20px;
            }
            h1 {
                @include font-big();
                margin: 0 20px;
            }
        }
        form {
            width: 350px;
            text-align: left;
            margin-top: 20px;
        }
        .toyota-button-small {
            margin: 0 auto;
        }

        .error {
            text-align: center;
            margin-top: 2rem;
            color: red;
        }
        label {
            margin: 4px auto;
            display: block;
            color: black;

            font-size: 14px;
        }
        input {
            margin-bottom: 16px;
        }
        input[type="submit"] {
            width: 100%;
        }
        input[type="text"],
        input[type="password"] {
            padding: 0.5rem 1rem;
            width: 100%;
            box-sizing: border-box;
            border-bottom: solid 1px #adadb0;
            font-size: 14px;
        }
        input[type="checkbox"] {
            margin-top: 5px;
            margin-right: 6px;
        }
    }
}
