@media only screen and (max-width: 1440px) {
    .kampagnen-title-box {
        &.vertical_center {
            justify-content: start !important;
        }
        * {
            font-size: 2rem !important;
            line-height: 1;
        }

        position: absolute;
        bottom: 20px;
        left: 0px;
        padding: 0;
        width: 100%;
        background-color: transparent !important;
        img {
            display: none !important;
        }
    }
    .kampagnen-title-single-wrapper {
        height: 200px;
    }
    .kampagnen-title-wrapper {
        .slick-slider {
            height: 200px;
        }
    }

    .kampagnen-title {
        height: 200px;
    }
}
.app-kampagnen-index {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    text-align: center;
    position: relative;
    a {
        text-decoration: none !important;
        font-weight: 400;
        display: flex;
    }
    .app-kampagnen-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        .app-kampagnen-item-icon {
            height: 20vw;
            width: 100%;
            background-color: #4d4f53;
            border-radius: 2px;
            margin-bottom: 8px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 50%;
        }
    }
}
