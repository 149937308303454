@mixin tablet_hoch {
    .app-willkommen {
        margin-top: 15px;
        .app-willkommen-box-container {
            display: flex;
            justify-content: space-between;
            width: 100%;
            a {
                font-weight: normal;
            }
            .app-willkommen-box {
                display: flex;
                flex-direction: column;
                color: white;
                background-color: #ff0022;
                width: 48%;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                font-size: 1.2rem;
            }
            img {
                height: 40%;
                width: 40%;
            }
            .icon-combine {
                display: flex;
                width: 100%;
                height: 40%;
                align-items: center;
                justify-content: center;
                img {
                    height: 80%;
                    width: 40%;
                }
            }
        }
    }
    .app-dash-title {
        font-weight: normal;
        font-size: 1.2rem;
        margin-bottom: 10px;
        margin-top: 16px;
    }
    .app-new-preview {
        margin-top: 20px;
        h2 {
            margin-bottom: 10px;
        }
        font-weight: bold;

        .slick-dots {
            bottom: 0;
        }
        .kampagnen-box {
            position: relative;
            background-size: cover !important;
            background-position: center !important;
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            text-align: left;
            text-decoration: none;
            transition: transform 0.2s ease;
            border-radius: 3px;
            .kampagnen-box-red-line {
                position: absolute;
                top: 0;
                right: 0;
                height: 5px;
                width: 48%;
                background-color: #ff0022;
                z-index: 5;
            }
            &:before {
                display: inline-block;
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                z-index: 1;
                content: "";
                border-radius: 3px;
                background: rgba(0, 0, 0, 0.3);
            }
            .kampagnen-box-content {
                z-index: 1;
                position: absolute;
                bottom: 1rem;
                left: 1.2rem;
                width: 90%;
                .title {
                    font-size: 22px;
                    color: white;
                    margin: 0;
                }

                span {
                    color: white;
                }
            }
        }
    }
}

@mixin tablet_square {
    .app-willkommen {
        .app-willkommen-box-container {
            justify-content: flex-start;
            .app-willkommen-box {
                width: 20%;
                margin-right: 24px;
            }
        }
    }
    .app-dash-title {
        margin-top: 24px;
    }
    .app-new-preview {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 8px;
    }
}

@media only screen and (max-width: 1440px) {
    @include tablet_hoch();
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    @include tablet_hoch();
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    @include tablet_square();
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    @include tablet_hoch();
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    @include tablet_square();
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    @include tablet_hoch();
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    @include tablet_square();
}
