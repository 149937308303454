.plan {
    display: block;
    position: relative;
    margin: 200px auto;
    max-width: 1850px;
}

.table {
    display: block;
    position: relative;
    margin: 50px auto;
    margin-top: 80px;
    max-width: 1800px;
    width: 100%;

    .plan-title {
        display: block;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        width: 1808px;
        min-height: 40px;
        color: white;
        background-color: #595d60;
        font-size: 13px;

        line-height: 40px;

        cursor: pointer;
        span::before {
            display: inline-block;
            content: "";
            background-image: url(.././../../assets/sort-down.svg);
            background-size: cover;
            width: 15px;
            height: 15px;
            margin-right: 5px;
            margin-left: 10px;
        }
    }
    .size-full {
        display: none;
    }

    .plan-content {
        width: 1808px;
        min-height: 40px;
        border-top: solid 0.5px grey;
        min-height: 60px;
        display: grid;
        grid-template-columns: 2fr 10fr;

        @media screen and (max-width: 700px) {
            // width: 1200px;
            // overflow-x: scroll;
        }

        .plan-content-sub {
            width: 100%;
            height: auto;
            display: grid;
        }
        .plan-sub-title {
            display: inline-block;
            box-sizing: border-box;
            color: #595d60;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 30px;
            padding-right: 30px;
            font-size: 12px;

            background: white;
            box-shadow: 0px 2px 2px #00000029;
            height: auto;
            @media screen and (max-width: 920px) {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
        .month {
            z-index: 5;
            position: relative;
            box-shadow: 0px 2px 2px #00000029;
            padding: 5px auto;
            height: auto;
            display: inline-grid;
        }
        .event {
            white-space: wrap;
            text-decoration: none;
            z-index: 6;
            margin-top: 2px;
            margin-bottom: 2px;
            background-color: rgba(170, 170, 170, 0.8);
            color: black;
            border-radius: 7px;
            text-align: center;
            color: white;
            font-size: 13px;
            cursor: pointer;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            p {
                line-height: 1.5rem;
                margin-bottom: 0;
                color: white;
            }
            img {
                max-height: 24px;
                max-width: 24px;
            }
            &:hover {
                transform: scale(1.01);

                transition: all 0.3s ease-in-out;
            }
        }
    }
    .plan-big-title {
        z-index: 10;
        background-color: #e6e6e6;
        .plan-sub-title {
            background: #e6e6e6;
            padding-left: 10px;
        }
        border-top: none;
        @media screen and (max-width: 700px) {
            position: static !important;
            // width: 1200px;
            // overflow-x: scroll;
        }
    }
    .month-title {
        z-index: 5;
        position: relative;
        display: grid;
        div {
            border-left: solid 1px lightgrey;
            text-align: center;
            padding: 20px;
            color: #595d60;
            cursor: pointer;
        }
        .month-list {
            &:hover {
                background-color: lightgrey;
            }
            &.selected {
                background-color: lightgrey;
            }
        }
        @media screen and (max-width: 700px) {
            position: static !important;
            // width: 1200px;
            // overflow-x: scroll;
        }
    }
}

.event-modal-container {
    position: fixed;
    padding: 10px;
    z-index: 10;
    background-color: rgba(#f6f6f6, 0.9);
    height: fit-content;
    width: fit-content;
    color: $toyota-grey;
    border-radius: 7px;
}

.plan-anime {
    max-height: 0;
    overflow: hidden;
    transition-delay: 0;
    width: 1808px;
    &.open {
        max-height: 5000px;
    }
}

@media only screen and (max-width: 1440px) {
    .plan-anime {
        max-height: 0;
        overflow: hidden;
        transition-delay: 0;
        transition: 0.5s ease-out max-height;
        width: 100%;
        &.open {
            max-height: 1000px;
            transition: 0.5s ease-in max-height;
        }
    }
}

.calendar-week-container {
    position: relative;
    width: 1808px;
    height: 40px;
    display: grid;
    grid-template-columns: 2fr 10fr;
    background-color: #e6e6e6;
    border-top: solid 1px #ccc;
    z-index: 10;
    .calendar-title {
        height: 100%;
        background-color: #e6e6e6;
        color: #595d60;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 12px;

        @media screen and (max-width: 920px) {
            padding-left: 10px;
            padding-right: 10px;
        }
        @media screen and (max-width: 700px) {
            position: static !important;
            // width: 1200px;
            // overflow-x: scroll;
        }
    }
    @media screen and (max-width: 700px) {
        /// width: 1200px;
        //  overflow-x: scroll;
    }
    .calendar-week-list {
        display: grid;
    }
    @media screen and (max-width: 700px) {
        position: static !important;
        // width: 1200px;
        // overflow-x: scroll;
    }
    .week-line {
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        font-size: 12px;
        line-height: 8px;

        height: 100%;
        .week-number {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.plan-layer {
    position: absolute;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 99;
    height: 100%;
    width: 102%;
}

.week-point-square {
    position: absolute;
    top: 3px;
    left: 50%;
    transform: translateX(-50%);
    height: 6px;
    width: 8px;

    &.active {
        background-color: $toyota-red;
    }
}
.week-point-arrow {
    position: absolute;
    top: 9px;
    left: 50%;
    transform: translateX(-50%);
    height: 0;
    width: 0;
    border: 4px solid transparent;
    border-top: 3px solid transparent;
    &.active {
        border-top: 5px solid $toyota-red;
    }
}
