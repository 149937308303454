.newspage-container{

  .rows{
   p{
     color:white;
     margin:0;
   }
   li{
     color:white;
   }
  }
  .news-rows{
    .kampagnen-row-container{
      &.layout-contained{
        padding:0 !important;
      }
    }
  }
}