.checklist-table {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    min-width: 1000px;
    margin-bottom: 5px;

    @media screen and (max-width: 700px) {
        width: 1000px;
        overflow-x: scroll;
    }
    .checklist-title {
        display: grid;
        grid-template-columns: 1fr 2.5fr 3fr 2fr 2fr 1fr 1fr;
        background: #e6e6e6;
        font-weight: bold;
        color: #595d60;
        border-radius: 5px 5px 0 0;
        .titlename {
            border-right: solid #e6e6e6 1px;
            padding-top: 25px;
            padding-left: 15px;
            padding-bottom: 5px;
        }
        .titledatum {
            padding-top: 25px;
            padding-left: 15px;
            padding-bottom: 5px;
        }
        .titlestatus {
            padding-top: 25px;
            padding-left: 15px;
            padding-bottom: 5px;
        }
    }
    .check-list-full-content {
        border-radius: 0 0 5px 5px;
    }
    .checklist-content {
        display: grid;
        grid-template-columns: 1fr 2.5fr 3fr 2fr 2fr 1fr 1fr;
        background-color: white;
        box-shadow: 0px 1px 2px #00000029;
        color: #6a6b70;

        .checklist-value {
            border-top: solid #e6e6e6 1px;
            border-right: solid #e6e6e6 1px;
            padding: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .checklist-check {
            display: flex;
            border-top: 1px solid #e6e6e6;
            align-items: center;
            justify-content: center;
            text-align: center;
            input {
                appearance: none;
                border: 1px solid #cccccc;
                height: 20px;
                width: 20px;
                border-radius: 3px;
                display: inline-block;
                &:checked {
                    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjYiIGhlaWdodD0iMjYiCnZpZXdCb3g9IjAgMCAxNzIgMTcyIgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDE3MnYtMTcyaDE3MnYxNzJ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iI2ZmZmZmZiI+PHBhdGggZD0iTTE0OS4yODU0NiwzMS4yOTM4N2wtMTEuODYxMTcsLTguMDYyNWMtMy4yODE4NSwtMi4yMjIzNiAtNy43NzgyNSwtMS4zNjk1OSAtOS45NzQ3NiwxLjg4NjQybC01OC4xNDMwMyw4NS43NDE1OWwtMjYuNzE5OTYsLTI2LjcxOTk2Yy0yLjc5MDg3LC0yLjc5MDg3IC03LjMzODk0LC0yLjc5MDg3IC0xMC4xMjk4MSwwbC0xMC4xNTU2NSwxMC4xNTU2NWMtMi43OTA4NywyLjc5MDg3IC0yLjc5MDg3LDcuMzM4OTQgMCwxMC4xNTU2NWw0MS4wODc3NCw0MS4wODc3NGMyLjI5OTg4LDIuMjk5ODggNS45MTc2Nyw0LjA1NzA5IDkuMTczNjgsNC4wNTcwOWMzLjI1NjAxLDAgNi41Mzc4NiwtMi4wNDE0NiA4LjY1Njg1LC01LjExNjU4bDY5Ljk3ODM3LC0xMDMuMjM2MThjMi4yMjIzNSwtMy4yNTYwMSAxLjM2OTU5LC03LjcyNjU2IC0xLjkxMjI2LC05Ljk0ODkyeiI+PC9wYXRoPjwvZz48L2c+PC9zdmc+")
                        50% 50% no-repeat;
                    background-color: red;
                    border-color: red;
                    background-size: 80%;
                }
            }
        }
        .checklist-action {
            border-top: solid #e6e6e6 1px;
            border-right: solid #e6e6e6 1px;
            padding: 15px;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .icon-email {
                padding-right: 5px;

                &:after {
                    vertical-align: -3px;
                    content: url(../../../../../../assets/Icon-email.svg);
                }
            }
            .icon-calendar-alt {
                padding-right: 5px;
                padding-left: 10px;
                &:after {
                    vertical-align: -3px;
                    content: url(../../../../../../assets/Icon-calendar-alt.svg);
                }
            }
        }
    }
    .toyota-button-small {
        margin-top: 30px;
        display: block;
        align-self: flex-end;
    }
}

.checklist-finish-container {
    display: grid;
    grid-template-columns: 1fr 11.5fr;
}

.checklist-finish {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.checklist-button-group {
    display: flex;
    .checklist-download {
        margin-left: 20px;
        display: flex;
    }
    .download-excel {
        background-image: url(../../../../../../assets/downlod_excel.svg);
        height: 24px;
        width: 24px;
        margin-right: 5px;
    }
}

.checklist-status {
    font-weight: bold;
    font-size: 1.3rem;
    text-align: right;
    display: block;
    padding: 0.5 1rem;
    border-radius: 3px;
    margin-bottom: 1rem;
}
