.akkordion {
  cursor: pointer;
  .item {
    .title {
      padding: 0.5rem 1rem;
      font-size: 16px;
      font-weight: bold;
      position: relative;
      .akkordion-arrow {
        position: absolute;
        height: 14px;
        width: auto;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        transition: all 0.2s ease;
        &.open {
          transition: all 0.2s ease;
          transform: translateY(-50%) rotate(90deg);
        }
      }
    }

    .content {
      overflow: hidden;
      box-sizing: border-box;

      .inner {
        padding: 0.5rem 1rem;
        li:before {
          left: 0rem;
        }
      }
      p {
        margin-bottom: 0;
      }
    }
    border-bottom: 1px solid #eee;
  }
}
