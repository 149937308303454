.kampagnen {
    align-items: center;
    width: 100%;
    position: relative;
    .back-to-aktion {
        padding: 1rem 1rem;
        display: block;
        color: black;
    }
}
.kampagnen-full-container {
    margin-left: 60px;
    &.opened {
        margin-left: 300px;
    }
    overflow: hidden;
    position: relative;
    .page-box {
        max-width: 100%;
        box-sizing: border-box;
        @media screen and (max-width: 1200px) {
            margin-left: 0;
            max-width: 100%;
        }
    }
    transition: all 0.5s ease-in-out;
    &.close {
        margin-left: 50px;
        @media screen and (max-width: 1200px) {
            width: 100%;
            margin: 0 auto;
        }
    }
    @media screen and (max-width: 1200px) {
        margin-left: 0;
        width: 100%;
        margin: 0 auto;
        &.opened {
            margin: 0 auto;
        }
    }
}
.rows {
    width: 100%;
}
.kampagnen-row-container {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    a {
        text-decoration: underline;
    }
    &.layout-contained {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
        max-width: 1400px;
        @media screen and (max-width: 1200px) {
            margin: 0 auto;
        }
        @media screen and (max-width: $break-small) {
            width: 100%;
            margin: 0 auto;
            padding: 0 1rem !important;
        }
    }
    &.overlay {
        &::before {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.2);
            z-index: 0;
        }
    }
    &.layout-stretched {
        max-width: 100%;
        > .kampagnen-row {
            max-width: 1400px;
            padding-left: 2rem !important;
            padding-right: 2rem !important;
            margin: 0 auto;
            @media screen and (max-width: 1200px) {
                margin: 0 auto;
            }
            @media screen and (max-width: $break-small) {
                width: 100%;
                margin: 0 auto;
                padding: 0 1rem !important;
            }
        }
    }
    ul {
        li {
            list-style: none;
            padding-left: 1rem;
            margin-bottom: 0.5rem;
            position: relative;
            &:before {
                content: "■";
                color: red;
                position: absolute;
                left: 0;
            }
        }
    }
}

.kampagnen-row {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 20px;
    margin: 0 auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    &.layout-full {
        max-width: 95%;
    }

    @media screen and (max-width: 1200px) {
        max-width: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;
        padding: 1rem 0;
        .kampagnen-column {
            max-width: 100%;
            overflow: hidden;
        }
    }
}

.kampagnen-column {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    @media screen and (max-width: 1200px) {
        padding: 0.5rem 0;
    }
    &.span_3 {
        grid-column-start: span 3;
    }
    &.span_4 {
        grid-column-start: span 4;
    }
    &.span_6 {
        grid-column-start: span 6;
    }
    &.span_9 {
        grid-column-start: span 9;
    }
    &.span_12 {
        grid-column-start: span 12;
    }
    &.lightblue {
        background-color: $toyota-lightblue;
    }
}

.kampagnen-content {
    position: relative;
    width: 100%;
    table {
        overflow-x: auto;
        max-width: 100% !important;
    }
}

.target-fix {
    position: relative;
    top: -80px;
    display: block;
    height: 0;
    overflow: hidden;
}

.kampagnen-background-red {
    background-color: $toyota-red;
    color: white;
    p {
        color: white;
    }
    h3 {
        color: white;
        font-size: 1.2rem;
    }
}

.kampagnen-background-darkgrey {
    background-color: $toyota-darkgrey;
    color: white;
    p {
        color: white;
    }
}

.kampagnen-background-white {
    background-color: white;
}

.kampagnen-padding-big {
    padding: 50px;
}

.kampagnen-padding-none {
    padding: 0;
}

.kampagnen-padding-middle {
    padding: 30px;
}

.kampagnen-padding-small {
    padding: 10px;
}

.plan-sidebar {
    box-sizing: border-box;
    position: fixed;
    display: block;
    width: 300px;
    height: 100%;
    left: 0;
    top: 80px;
    z-index: 500;
    background: white;
    padding-top: 25px;
    padding-left: 45px;
    transition: all 0.5s ease-in-out;
    h4,
    ul,
    .back {
        transition: all 0.5s ease-in-out;
    }
    h4 {
        position: relative;
        font-size: 13px;
        font-weight: bold;
        color: #aaafb2;
    }
    ul {
        @include font-small();
        color: #58595b;
        margin-top: 20px;
        li {
            font-size: 14px;
            margin-bottom: 1rem;
            .submenu {
                margin-top: 0.5rem;
                li {
                    margin-bottom: 0.5rem;
                }
            }
            a {
                font-weight: 300;
                &.active {
                    font-weight: bold;
                }
            }
        }
    }
    .back {
        margin-top: 35px;
        color: #aaafb2;
        font-size: 13px;
    }

    .menu-close {
        color: #aaafb2;
        font-size: 13px;
        font-weight: bold;
        cursor: pointer;
    }

    &.close {
        transform: translateX(-235px);
        transition: all 0.5s ease-in-out;
        .side-bar-toggle {
            &::before {
                transform: rotate(0deg);
            }
            h4 {
                display: none;
            }
        }
        .plan-sidebar-info {
            display: none;
        }
    }
    .side-bar-toggle {
        margin-right: 20px;
        cursor: pointer;
        &::before {
            content: "";
            background: url(../../../../assets/icons-openmenu.svg);
            background-size: contain;
            background-repeat: no-repeat;
            height: 30px;
            width: 30px;
            position: absolute;
            right: 18px;
            transform: rotate(180deg);
        }
        margin-bottom: 80px;
        h4 {
            float: right;
            margin-right: 35px;
            line-height: 26px;
        }
    }
    @media screen and (max-width: 1200px) {
        display: none;
    }
}

.kampagnen-text-content {
    td {
        ul {
            li:before {
                position: relative;
            }
        }
    }
}

.hidden-wrapper {
    position: relative;
}

.hidden-wrapper-title {
    position: relative;
    padding: 15px 0 7px 30px;
}

.arrow {
    transform: rotate(90deg);
    z-index: 10;
    position: absolute;
    top: 23px;
    right: 30px;
    cursor: pointer;
    transition: 0.2s all ease;
    &.closed {
        transform: rotate(0deg);
        transition: 0.2s all ease;
    }
}

ul.submenu {
    margin: 0;
    padding: 0;
    margin-left: 1rem;
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 12px;
}

.start-title {
    margin-bottom: 2rem;
}

.none {
    display: none;
}

.rowVideo {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.kampagnen-content {
    &.zeitplan {
        overflow-x: auto;
    }
    &.checkliste {
        overflow-x: auto;
    }
    &.text {
        @media screen and (max-width: 700px) {
            overflow-x: auto;
        }
    }
    &.product_preview {
        height: 100%;
    }
}

.to-top-button {
    position: fixed;
    bottom: 90px;
    left: 50%;
    transform: translateX(-50%);
    padding: 7px 30px;
    color: white;
    background-color: #ff0022;
    border-radius: 30px;
    z-index: 999;
}
