@mixin icon-style {
    width: 50px;
    height: 50px;
    justify-self: center;
    &.center {
        margin: 0 auto;
        margin-bottom: 20px;
    }
    &.left {
        margin-right: 20px;
    }
    &.right {
        margin-left: 20px;
    }
}

.icon-with-text {
    position: relative;
    overflow: visible;
    a {
        display: block;
        transition: 0.2s;
        &:hover {
            transform: translateY(2px) scale(1.05);
            transition: 0.2s;
        }
    }
    p {
        a {
            display: inline;
        }
    }
    &.left {
        display: flex;
        flex-direction: row;
        align-items: center;
        .icon-icon {
            flex-shrink: 0;
        }
        .kampagnen-text-content {
            flex-shrink: 1;
        }
    }
    &.right {
        display: flex;
        align-items: center;
    }
    .icon-icon {
        @include icon-style();
    }

    li {
        padding-bottom: 10px;
    }
}

.kampagnen-text-content {
    position: relative;
    h3 {
        margin-top: 10px;
    }
}
