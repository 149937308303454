.korb-modal-container {
    z-index: 1000;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
}
.korb-modal {
    z-index: 1001;
    box-sizing: border-box;
    position: relative;
    padding: 50px;
    padding-right: 0;
    background-color: white;
    height: 950px;
    overflow: hidden;

    .close-modal {
        height: 20px;
        width: 20px;
        position: absolute;
        top: 20px;
        right: 20px;
        background-image: url("../../../../../../assets/close.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        cursor: pointer;
    }
    .korb-modal-content {
        max-height: 800px;
        overflow: scroll;
        padding-right: 50px;
        box-shadow: solid 1px #ccc;
    }
    .korb-save {
        float: right;
        margin-top: 30px;
        margin-right: 30px;
        .save {
            vertical-align: -3px;
            padding-right: 5px;

            &:after {
                content: url(../../../../../../assets/save.svg);
            }
        }
    }
    .disposition-link {
        background-color: #58595b;
        float: right;
        margin-top: 30px;
        margin-right: 20px;
    }
}

.korb-input {
    border-bottom: solid #e6e6e6 1px;
    border-left: solid #e6e6e6 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: solid #e6e6e6 1px;
    input {
        box-sizing: border-box;
        margin: 0 auto;
        width: 70%;
        border-radius: 5px;
        border: 1px solid rgba($color: #000, $alpha: 0.5);
        padding: 5px;

        text-align: right;
    }
}
