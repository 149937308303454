@import "../../../../../../style/variable.scss";
.feedback-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 0.2fr;
    @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 0.5fr;
    }
    @media screen and (max-width: 700px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
    background-color: #58595b;
}

.feedback-img {
    background-position: center;
    background-size: cover;
}

.feedback-content-container {
    display: flex;
    justify-content: center;
    background-color: white;
}

.feedback-content {
    padding: 150px;
    @media screen and (max-width: 800px) {
        padding: 32px;
    }
}

.feedback-zeil {
    font-size: 1rem;
    padding-left: 30px !important;
}

.feedback-button {
    a {
        color: white;
        font-size: 1.5rem;
        text-decoration: none;
    }
}
.feedback-endtext1 {
    grid-column: span 2;
    align-self: center;
    text-align: center;
    margin-right: 50px;
    padding: 100px 0;
    h2 {
        color: white;
        margin-bottom: 30px;
    }
    .feedback-button {
        a {
            text-decoration: none;
        }
    }

    @media screen and (max-width: 1200px) {
        justify-self: center;
        margin-right: 0px;
    }
}
