.produkttable {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  word-break: normal;
  margin-bottom: 5px;

  @media screen and (max-width: 700px) {
    width: 800px;
    overflow-x: scroll;
  }

  .produkttable-title {
    display: grid;
    background: #e6e6e6;
    font-weight: bold;
    color: #595d60;
    border-radius: 5px 5px 0 0;
    .produkttable-title-columns {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px;
    }
  }
}
.warenkorb-button {
  margin-top: 20px;
}
