.header-bar {
  position: fixed;
  width: 100%;
  height: 80px;
  top: 0;
  font-weight: bold;
  background: $toyota-black;
  color: white;
  line-height: 80px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .logo-wrap {
    display: flex;
    flex-direction: row;
    align-content: center;
    height: 100%;
    position: absolute;
    left: 35px;
    top: 0;
    @media screen and (max-width: $break-small) {
      left: 20px;
    }
    .logo {
      height: 100%;
      width: 45px;
      background-image: url(../../assets/toyota-logo.svg);
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 20px;
    }
    .logo-wrap-text {
      font-size: 30px;
      height: 100%;
      color: white;
      @media screen and (max-width: $break-small) {
        display: none;
      }
      position: relative;
      .beta {
        position: absolute;
        font-size: 14px;
        right: -20px;
        bottom: -18px;
      }
    }
  }
  .bar-list {
    display: flex;
    @media screen and (max-width: 1024px) {
      display: none;
    }

    li {
      padding: 0;
      display: block;
      box-sizing: border-box;
      font-size: 16px;
      height: 100%;
      position: relative;
      .active {
        background-color: rgba($toyota-light-grey, 0.8);
      }
      &:hover {
        background-color: rgba($toyota-light-grey, 0.8);
        transition: all ease 0.3s;
      }
      a {
        padding: 0 2vw;
        color: white;
        display: block;
        height: 100%;
        @media screen and (max-width: 1500px) {
          padding: 0 1vw;
        }
      }
    }
  }
  .konto {
    position: absolute;
    height: 30px;
    width: 30px;
    font-size: 12px;
    border: 1px solid #ccc;
    border-radius: 50%;
    top: 23px;
    right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.selected {
      color: $toyota-red;
      border: 1px solid $toyota-red;
    }
  }
  .user-id {
    position: absolute;
    text-align: right;
    top: 30px;
    right: 75px;
    height: 30px;
    width: 60px;
    font-size: 12px;
    display: grid;
    grid-template-rows: 1fr 1fr;
    line-height: 5px;
  }
  .search {
    position: absolute;
    height: 80px;
    width: 28px;
    background: url(../../assets/icon-search.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    top: 0;
    right: 170px;
    cursor: pointer;
    &.selected {
      background: url(../../assets/icon-search-selected.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .help {
    position: absolute;
    height: 80px;
    width: 30px;
    background: url(../../assets/icons-help.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    top: 0;
    right: 220px;
    cursor: pointer;
  }
  .shopping-cart {
    position: absolute;
    height: 80px;
    width: 30px;
    background: url(../../assets/icon-shopping-cart.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    top: 0;
    right: 198px;
    cursor: pointer;
  }
  .shopping-cart-number {
    box-sizing: border-box;
    text-align: center;
    line-height: 18px;
    position: absolute;
    height: 23px;
    width: 23px;
    font-size: 13px;
    background-color: $toyota-red;
    top: 18px;
    right: 185px;
    border-radius: 50%;
  }
  .toolbar {
    position: absolute;
    height: 80px;
    width: 30px;
    background: url(../../assets/toolbar.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    top: 0;
    right: 15px;
    cursor: pointer;
  }
}

.konto-searchbar {
  position: fixed;
  width: 500px;
  height: 100%;
  top: 80px;
  right: -540px;
  z-index: 1000;
  background: white;
  padding-top: 40px;
  padding-left: 40px;
  transition: transform 0.2s ease;
  box-shadow: -2px 0 2px #f6f6f6;
  transition: all 0.5s ease;

  &.open {
    right: 0;
    transition: all 0.5s ease;
  }
  @media screen and (max-width: $break-small) {
    width: 80vw;
  }
  i {
    float: right;
    margin-right: 20px;
    margin-top: -20px;
    cursor: pointer;
    &:before {
      content: url(../../assets/close.svg);
      font-size: 18px;
    }
  }
  h2 {
    margin-top: 30px;
  }

  input {
    box-sizing: border-box;
    border: 1px solid #ccc;
    width: 80%;
    height: 36px;
    font-size: 16px;
    padding: 5px;
    padding-left: 40px;
    background: url(../../assets/icons-search-grey.svg);
    background-repeat: no-repeat;
    background-size: 7%;
    background-position: 5px;
  }
  #search-bar {
    margin-top: -30px;
  }

  #search-result {
    display: grid;
    grid-template-rows: auto;
    padding-right: 15px;
    margin-top: 30px;
    width: 80%;
    height: 50vh;
    overflow-y: auto;
  }
  .search-result-item {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 20px;
    padding-bottom: 10px;
    border-bottom: 1px #ccc solid;
    width: 100%;
    span {
      color: $toyota-light-grey;
    }
    h3 {
      font-size: 1.2rem;
      display: inline-block;
      width: 50%;
      grid-column-start: 1;
      grid-column-end: 4;
      margin-bottom: 10px;
    }
  }
  .icon-next-grey {
    height: 30px;
    width: 30px;
    grid-column-start: 4;
    align-self: center;
    background: url(../../assets/icon-next-grey.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
.konto-sidebar {
  position: fixed;
  width: 300px;
  height: 100%;
  right: -340px;
  top: 80px;
  z-index: 1000;
  background: white;
  padding-top: 40px;
  padding-left: 40px;
  transition: transform 0.2s ease;
  box-shadow: -2px 0 2px #f6f6f6;
  transition: all 0.5s ease;
  &.open {
    right: 0;
    transition: all 0.5s ease;
  }
  i {
    float: right;
    margin-right: 20px;
    margin-top: -20px;
    cursor: pointer;
    &:before {
      content: url(../../assets/close.svg);
      font-size: 18px;
    }
  }
  .konto-info {
    font-size: 13px;
    margin-bottom: 28px;
  }
  span {
    font-size: 13px;
    color: red;
    cursor: pointer;
  }
  .toolbar-list {
    a {
      color: black;
    }
    li {
      font-size: 20px;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}

.sidebar-anime-enter {
  transform: translateX(100%);
}
.sidebar-anime-active,
.sidebar-anime-enter-done {
  transform: translateX(0);
  transition: transform 0.2s ease;
}
.sidebar-anime-exit {
  transform: translateX(0);
}
.sidebar-anime-exit-active {
  transform: translateX(100%);
  transition: transform 0.2s ease-in;
}

.searchbar-anime-enter {
  transform: translateX(100%);
}
.searchbar-anime-active,
.searchbar-anime-enter-done {
  transform: translateX(0);
  transition: transform 0.2s ease;
}
.searchbar-anime-exit {
  transform: translateX(0);
}
.searchbar-anime-exit-active {
  transform: translateX(100%);
  transition: transform 0.2s ease-in;
}

.loader {
  position: fixed;
  width: 100%;
  height: 100vh;

  text-align: center;
  z-index: 999;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 150px;
  }
  svg {
    animation: rotate 2s infinite linear;
    margin: 200px;
    @media screen and (max-width: $break-small) {
      margin: 50px;
    }
  }
  &.white {
    background-color: #f6f6f6 !important;
    position: relative;
    height: 50vh;
  }
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @media screen and (max-width: $break-small) {
    height: 112%;
    width: 113%;
  }
}

#PassForm {
  border-bottom: 1px solid #ccc;
  padding-bottom: 2rem;
  margin-right: 2rem;
  label {
    color: #ccc;
    font-size: 12px;
  }
  button {
    margin-top: 1rem;
    font-size: 13px;
  }
  input {
    display: block;
    border: 1px solid #ccc;
    padding: 0.5rem 1rem;
    border-radius: 5px;
  }
}
