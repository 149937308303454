.bestell-button {
    display: grid;
    grid-template-columns: 1fr;

    height: 60px;
    .dis-bestellen {
        justify-self: end;
    }
    .dis-save {
        justify-self: end;
        margin-top: -10px;
        background-color: $toyota-grey;
    }
    .save-info {
        justify-self: end;
        color: $toyota-red;
    }
}
.save {
    vertical-align: -2px;
    padding-right: 5px;

    &:after {
        content: url(../../../assets/save.svg);
    }
}
.korb-table {
    margin-top: 50px;
}
.warenkorb-korb-input {
    display: grid;
    grid-template-columns: 9fr 2.5fr;
    align-items: center;
    justify-items: left;
}

.korb-add,
.korb-minus {
    color: white;
    cursor: pointer;

    &:hover {
        color: $toyota-grey;
    }
}

.korb-delete {
    color: white;
    cursor: pointer;
    &:hover {
        color: $toyota-red;
    }
}
