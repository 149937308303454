.linklist.tags {
	li {
		float: left;
		margin-left: 0;
		padding: 0.5rem 1rem;
		background: #aaafb2;
		border-radius: 5px;
		margin-right: 0.5rem;
		font-size: 1.2rem;
		transition: all 0.3s ease;
		a {
			color: #fff;
		}
		&:before {
			display: none;
		}
		&:hover {
			transition: all 0.3s ease;

			background: #333;
		}
	}
}
