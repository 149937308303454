.popup-background {
    z-index: 99998;
    top: 0;
    left: 0;
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.8);
}

.popup-container {
    z-index: 99999;
    width: 80%;
    max-width: 1000px;
    max-height: 75vh;
    margin: 10vh auto;
    box-sizing: border-box;
    position: relative;
    padding: 50px;
    background-color: white;
    border-radius: 5px;
    overflow: scroll;
}

.close-popup {
    z-index: 99999;
    position: fixed;
    top: 100px;
    right: 100px;
    height: 30px;
    width: 30px;
    background-image: url("../../../../../../assets/close_white.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
}
