.product-preview-box {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    background-color: white;
    box-shadow: 0px 1px 2px #00000029;
    border-radius: 5px;
    margin-bottom: 3px;
}

.product-preview-img {
    width: 100%;
    height: 200px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px 5px 0 0;
}

.product-preview-splash {
    display: inline-block;
    padding: 8px 12px;
    position: absolute;
    top: 5%;
    right: 0;
    max-width: 45%;
    border-radius: 3px 0 0 3px;
    color: white;
    font-size: 20px;
    font-weight: bold;
    box-shadow: 0px 1px 2px #00000029;
    background-color: #ff0022;
    z-index: 100;
}
.product-preview-button {
    position: absolute;

    bottom: 20px;
    left: 30px;
}

.product-preview-content {
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: auto;
    padding: 0px 30px 50px 30px;

    .secondary {
        background-color: #aaafb2;
    }
    .product-preview-text {
        width: 100%;
    }
    .product-preview-popup {
        box-sizing: content-box;
        background-color: #4d4f53;
        color: white;
        p {
            color: white;
        }
        li {
            color: white;
        }
    }
}
