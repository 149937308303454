.menge-plan {
  word-break: normal;
  min-width: 1200px;
}

.orderplan-subline {
  p {
    margin-top: 10px !important;
    margin-bottom: 0 !important;
  }
}

.download-popup-container {
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  position: absolute;
  top: 60%;
  left: 50%;
}

.korb-korb {
  background-image: url(../../../assets/icon-shopping-cart-red.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  height: 25px;
  width: 25px;
}

.oder-korb-input {
  display: grid;
  align-items: center;
}

.filter {
  display: flex;
  flex-wrap: wrap;
  max-width: 900px;
  .input-date {
    margin-top: 20px;
    @media screen and (max-width: 900px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
    }
    input {
      border: 1px #ccc solid;
      margin-left: 10px;
      margin-right: 30px;
      height: 30px;
      padding-left: 10px;
      width: 129px;
    }
  }
}

.korb-anime {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: $toyota-red;
  animation: ball;
  animation-duration: 0.8s;
  z-index: 1200;
  animation-timing-function: linear;
}
.products-search {
  box-sizing: border-box;
  width: 200px;
  height: 30px;
  padding: 0px;

  padding-left: 35px;
  background: url(../../../assets/icons-search-grey.svg);
  background-color: white;
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 5px;
  border: 1px solid #ccc;
}

.products-filter {
  margin-left: 1rem;
  box-sizing: border-box;
  width: 350px;
  height: 30px;
  padding-left: 35px;
  background: url(../../../assets/icons-search-grey.svg);
  background-color: white;
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 5px;
  border: 1px solid #ccc;
  @media screen and (max-width: 900px) {
    margin-top: 5px;
    margin-left: 0;
  }
  @media screen and (max-width: $break-small) {
    margin-top: 5px;
    margin-left: 0;
  }
}

.produkttable-table {
  width: 100%;
}

.child-produkttable-title {
  background-color: lightgrey;
  display: flex;
  padding: 10px 15px;
  justify-content: space-between;
  font-weight: bold;
  .close {
    content: "";
    background-image: url(.././../../assets/sort-down.svg);
    background-size: cover;
    width: 30px;
    height: 30px;
    transform: rotate(180deg);
    margin-right: 25px;
    cursor: pointer;
  }
}

.produkttable-title-columns {
  cursor: pointer;
  &.selected {
    background-color: lightgrey;
    .sort-pfeil {
      display: inline-block;
      content: "";
      background-image: url(.././../../assets/sort-down.svg);
      background-size: cover;
      width: 15px;
      height: 15px;
      vertical-align: -3px;
      margin-left: 5px;
      &.top {
        transform: rotate(0.5turn);
      }
    }
  }
}

.produkttable-content {
  display: grid;
  background-color: white;
  color: #6a6b70;
  box-shadow: 0px 1px 2px #00000029;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
  .produkttable-name {
    border-bottom: solid #e6e6e6 1px;
    padding: 15px;
    padding-left: 5px;
    border-left: solid #e6e6e6 1px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    .more-button {
      margin-right: 10px;
      img {
        &.closed {
          transform: rotate(90deg);
          transition: all 0.2s ease;
        }
        &.open {
          transform: rotate(-90deg);
          transition: all 0.2s ease;
        }
        height: 20px;
        width: auto;
      }
    }
    .pflicht {
      height: 20px;
      width: 20px;
      right: 0;
      box-sizing: border-box;
      align-self: center;
      justify-self: flex-end;
      display: flex;
      position: absolute;
      right: 10px;
      justify-content: center;
      align-items: center;
      line-height: 1;
      color: white;
      font-weight: bold;
      background-color: $toyota-light-grey;
      border-radius: 50%;
    }
  }
  .produkttable-bestellnummer {
    border-bottom: solid #e6e6e6 1px;
    border-left: solid #e6e6e6 1px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .produkttable-extracolumn {
    border-bottom: solid #e6e6e6 1px;
    border-left: solid #e6e6e6 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}

@keyframes ball {
  0% {
  }
  50% {
    top: 30px;
    right: 17%;
  }
  70% {
    top: 15px;
    right: 15%;
  }
  100% {
    top: 20px;
    right: 200px;
  }
}

.page-controller {
  width: 100%;
  margin-top: 50px;
  display: flex;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  .page-number-container {
    display: flex;
  }
  .page-number {
    margin-right: 5px;
    cursor: pointer;
    margin-right: 10px;
  }
  .aktuelle {
    font-weight: bold;
  }
  .page-back {
    margin-right: 30px;
    cursor: pointer;
    cursor: pointer;
    height: 18px;
    width: 18px;
    background-image: url(../../../assets/icons-previous-page.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .page-next {
    margin-left: 20px;
    cursor: pointer;
    height: 18px;
    width: 18px;
    background-image: url(../../../assets/icons-next-page.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.download-excel {
  background-image: url(../../../assets/downlod_excel.svg);
  height: 24px;
  width: 24px;
}

.paste-icon {
  background-image: url(../../../assets/vorjahrespaste.svg);
  background-position: center;
  height: 25px;
  width: 20px;
  background-repeat: no-repeat;
  margin: 0 auto;
}
.order-button-group-container {
  float: right;
  @media screen and (max-width: $break-small) {
    float: none;
  }
}
.order-button-group {
  display: flex;
  margin-top: 45px;
  justify-content: flex-end;

  @media screen and (max-width: $break-small) {
    position: relative;
    justify-content: flex-start;
    display: flex;
    margin-top: 0;
    margin-bottom: 20px;
  }
}
.count-paste {
  width: 30px;

  justify-content: center;
  margin-right: 10px;
  @media screen and (max-width: $break-small) {
  }
}
