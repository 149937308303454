.public-wrapper {
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../../assets/mainimage.jpg");
    background-size: cover;
    .public-box {
        width: 1000px;
        padding: 2rem;
        background: white;
        box-shadow: 0px 2px 2px #00000029;
        text-align: center;
        display: flex;
        flex-direction: row;
        .public-left {
            display: flex;
            flex-direction: column;
            text-align: left;
            padding: 2rem;
            max-width: 500px;
            border-right: 1px solid #ddd;
        }
        .public-right {
            width: 500px;
            padding: 2rem;
            background-image: url(../../../assets/public.jpeg);
            background-position: center;
            background-size: cover;
        }
        .download-icons {
            img {
                height: 50px;
                margin-right: 15px;
                cursor: pointer;
            }
        }
        h3 {
            margin: 10px 0;
        }
    }
}
