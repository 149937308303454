.kampagnen-box {
  box-shadow: 0px 1px 2px #00000029;
  border-radius: 5px;

  position: relative;

  P {
    margin-bottom: 10px;
  }
}

.kampagnen-background-red,
.kampagnen-background-darkgrey,
.kampagnen-background-lightgrey {
  ul {
    li {
      &:before {
        color: white !important;
      }
    }
  }
  li,
  a {
    color: white;
  }
}
