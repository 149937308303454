.app-car-bild {
    z-index: 1;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0px 2px 6px #00000029;
    height: 150px;
    background-position: center;
    background-size: cover;
    position: relative;

    &:before {
        content: "";
        display: inline-block;
        z-index: 2;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 150px;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.3);
    }
    .app-car-bild-text {
        z-index: 3;
        color: white;
        position: absolute;
        bottom: 10px;
        left: 10px;
        font-weight: bold;
        font-size: 1.2rem;
    }
}

.app-car-icon-container {
    display: flex;
    padding-top: 5px;
    align-items: center;
    margin-left: 5px;
    padding-bottom: 10px;
    .line {
        width: 30%;
        margin: 0 1px;
        height: 1px;
        background: linear-gradient(black 0%, black 100%);
    }
    .app-do-button-euro-container {
        display: flex;
        align-items: center;
    }

    .app-do-button-procent-container {
        display: flex;
        align-items: center;
    }
    .app-rabatta-info-container {
        margin-right: 5px;
    }
    .app-do-button-euro {
        box-sizing: border-box;
        font-weight: bold;
        align-items: center;
        justify-content: center;
        height: 25px;
        width: 25px;
        text-align: center;
        white-space: nowrap;
        font-size: 10px;
        transition: 0.3s all ease;
        margin: 0 5px;
        background-image: url("../../../../../app/app_assets/app-donation-red.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .app-do-button-procent {
        box-sizing: border-box;
        font-weight: bold;
        align-items: center;
        justify-content: center;
        height: 25px;
        width: 25px;
        text-align: center;
        white-space: nowrap;
        font-size: 10px;
        transition: 0.3s all ease;
        margin: 0 5px 0 5px;
        background-image: url("../../../../../app/app_assets/app-discount-red.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}
.app-do-line {
    width: 90%;
    height: 1px;
    background: linear-gradient(#ccc 0, #ccc 100%);
    margin: 5px auto;
    margin-top: 10px;
}
.app-akkordion {
    margin-bottom: 8px;
    .item {
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        border-bottom: none;
        .item-tips {
            margin: 0 5px;
            word-break: keep-all;
        }
        .item-rabatte {
            color: #ff0022;
            margin-left: 10px;
        }
        .title {
            max-width: 200px;
            font-weight: normal;
            &.open {
                grid-column: span 7;
                transition: 0.3s all ease;
            }
        }
    }

    .item-container {
        padding-left: 20px;
        border-radius: 5px;
    }
    .app-akkordion-title {
        font-weight: bold;
        font-size: 18px;
        font-family: "ToyotaType", sans-serif;
        margin-top: 30px;
        margin-bottom: 10px;
        position: relative;
        padding-left: 20px;
        &:before {
            content: "-";
            font-weight: normal;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    ::-webkit-scrollbar {
        width: 5px;
        border-radius: 5px;
        background-color: #eee;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #aaa;
    }
    ::-webkit-scrollbar:horizontal {
        background-color: transparent;
    }
    .content {
        background-color: white;
        max-height: 0;
        overflow: hidden;
        transition: 0.3s all ease;
        border-bottom: 1px #eee solid;
        .inner {
            padding: 0 10px;

            p {
                margin: 10px 0;
            }
            img {
                display: inline-block;
            }
        }
        &.open {
            max-height: 450px;
            height: fit-content;
            overflow: scroll;
            transition: 0.5s all ease-in-out;
        }
    }
    .arrow {
        right: 10px !important;
    }
    .akkordion-arrow {
        position: absolute;
        height: 14px;
        width: auto;
        top: 15px;
        right: 10px;
        transition: all 0.2s ease;
        &.open {
            transition: all 0.2s ease;
            transform: rotate(90deg);
        }
    }
}

.app-car {
    display: flex;
    flex-direction: column;
    .app-akkordion-group {
        border-left: 1px solid black;
    }
}
